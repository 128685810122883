<template>
  <b-container>
    <b-table responsive="sm" :items="items" />
  </b-container>
</template>

<script>
import getEnv from '@/utils/env'
import { BTable } from 'bootstrap-vue'

export default {
  name: 'About',
  components: {
    BTable,
  },
  data() {
    return {
      items: [
        {
          key: 'Label',
          value: 'komp-ui',
        },
        {
          key: 'Description',
          value: 'This application serves as an intuitive user-interface for the Kompozition backend services.',
        },
        {
          key: 'Node Environment',
          value: process.env.NODE_ENV,
        },
        {
          key: 'Release',
          value: getEnv('VUE_APP_KOMPOZITION_RELEASE'),
        },
        {
          key: 'Build Agent',
          value: getEnv('VUE_APP_KOMPOZITION_BUILD_AGENT'),
        },
        {
          key: 'Build Information',
          value: `${getEnv('VUE_APP_KOMPOZITION_BUILD_DATE')} (Commit: ${getEnv('VUE_APP_KOMPOZITION_BUILD_COMMIT')}) (Job Id: ${getEnv('VUE_APP_KOMPOZITION_BUILD_JOB_ID')})`,
        },
        {
          key: 'Build Commit',
          value: getEnv('VUE_APP_KOMPOZITION_BUILD_COMMIT'),
        },
        {
          key: 'API Base URL',
          value: `${window.location.protocol}//api.${window.location.host}`,
        },
        {
          key: 'Authentication Server URL',
          value: `${window.location.protocol}//auth.${window.location.host}/auth`,
        },
        {
          key: 'Authentication Realm (Client)',
          value: `${process.env.VUE_APP_AUTH_KEYCLOAK_REALM} (${process.env.VUE_APP_AUTH_KEYCLOAK_CLIENT_ID})`,
        },
        {
          key: 'Log level',
          value: process.env.VUE_APP_LOGGING_LEVEL,
        },
        {
          key: 'Sentry.io enabled',
          value: getEnv('VUE_APP_SENTRY_ENABLED'),
        },
        {
          key: 'Sentry.io DSN',
          value: getEnv('VUE_APP_SENTRY_DSN'),
        },
        {
          key: 'Feature Flag: JointJS',
          value: getEnv('VUE_APP_USE_JOINTJS'),
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>
